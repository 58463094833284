// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

$(document).ready(() => {

    // Fixes initial transitions firing on page load
    $('body').removeClass('preload');


    // ----------------------------------------
    // THIRD-PARTY PLUGIN CONFIG
    // ----------------------------------------


    // ----------------------------------------
    // GLOBAL SCRIPTS (header/footer, etc)
    // ----------------------------------------

    // Main nav button
    $('.js-nav-btn').click(() => {
        $('.js-nav').toggleClass('open');
        if ($('#js-headsearch-field').is(':visible')) {
            $('#js-headsearch-field').fadeOut(250);
        }
    });

    // Head search button
    const searchField = $('#js-headsearch-field');
    const searchForm = $('#js-headsearch-form');
    $('#js-headsearch').click(() => {
        const searchVal = searchField.val();

        if (searchField.is(':visible')) {
            if (searchVal !== '') {
                searchForm.submit();
            } else {
                searchField.fadeToggle(250);
            }
        } else {
            $('.js-nav').removeClass('open');
            searchField.fadeToggle(250);
        }
    });


});
